import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "this-step-takes-our-static-components-and-populates-them-with-data-from-the-github-graphql-api--loading-states-and-all-well-be-displaying-carbon-repository-information-in-a-data-table"
    }}>{`This step takes our static components and populates them with data from the GitHub GraphQL API – loading states and all. We’ll be displaying Carbon repository information in a data table.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install dependencies</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Create access token</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Connect to Apollo</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Fetch data</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Populate data table</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add loading</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add pagination</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h3 {...{
      "id": "preview"
    }}>{`Preview`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://developer.github.com/v4/"
      }}>{`GitHub GraphQL API`}</a>{` is very well documented, and even though the focus of this tutorial isn’t learning and using GraphQL, it’s a great opportunity to fetch Carbon-related data for this Carbon tutorial.`}</p>
    <p>{`To do so, we’ll be using Apollo Client, the front-end component of the `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/intro/platform"
      }}>{`Apollo Platform`}</a>{`. Apollo provides several open source tools for using GraphQL throughout your application’s stack. Apollo Client is a sophisticated GraphQL client that manages data and state in an application.`}</p>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://react-step-4--carbon-tutorial.netlify.com"
      }}>{`preview`}</a>{` of what you will build (see repositories page):`}</p>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe height="400" title="Carbon Tutorial Step 3" src="https://react-step-4--carbon-tutorial.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" />
  </Column>
    </Row>
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/react-step-1#fork-clone--branch"
      }}>{`step 1 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b react-step-3 upstream/react-step-3
`}</code></pre>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn start
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/react-step-2"
      }}>{`previous step`}</a>{` left off. Stop your app with `}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{` and let’s get everything installed.`}</p>
    <h2 {...{
      "id": "install-dependencies"
    }}>{`Install dependencies`}</h2>
    <p>{`We’ll need to install three new dependencies to incorporate Apollo into our application.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`apollo-boost`}</inlineCode>{` - package containing everything you need to set up Apollo Client`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`graphql`}</inlineCode>{` - parses your GraphQL queries`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-apollo`}</inlineCode>{` - Apollo integration for React`}</li>
    </ul>
    <p>{`Install them with the command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add apollo-boost graphql react-apollo
`}</code></pre>
    <h2 {...{
      "id": "create-access-token"
    }}>{`Create access token`}</h2>
    <p>{`You’ll need a personal access token from your GitHub account in order to make requests to the GitHub API. Check out `}<a parentName="p" {...{
        "href": "https://help.github.com/en/articles/creating-a-personal-access-token-for-the-command-line"
      }}>{`this guide`}</a>{` to see how to get one.`}</p>
    <p>{`When you get to the scope/permissions step, you can leave them all unchecked. We don’t need any special permissions, we just need access to the public API.`}</p>
    <p>{`Once you have your token, we need to put it in a place where create-react-app can use it. When your application is being built and developed, create-react-app will parse environmental variables in any file that starts with `}<inlineCode parentName="p">{`.env`}</inlineCode>{` and make them available under `}<inlineCode parentName="p">{`process.env.MY_VARIABLE`}</inlineCode>{`.`}</p>
    <p>{`One caveat is that we need to start our variables with `}<inlineCode parentName="p">{`REACT_APP_`}</inlineCode>{`. You can read more about environmental variables in `}<a parentName="p" {...{
        "href": "https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables"
      }}>{`create-react-app’s guide`}</a>{`.`}</p>
    <p>{`Since we don’t want to commit this file to Git, we can put it in `}<inlineCode parentName="p">{`.env.local`}</inlineCode>{` which is in our `}<inlineCode parentName="p">{`.gitignore`}</inlineCode>{` list. Your file should just have a single line like this one, where the `}<inlineCode parentName="p">{`x`}</inlineCode>{`s are replaced with your unique token.`}</p>
    <h5 {...{
      "id": "envlocal"
    }}>{`.env.local`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`REACT_APP_GITHUB_PERSONAL_ACCESS_TOKEN=xxxxxx
`}</code></pre>
    <p>{`Go ahead and start your app with `}<inlineCode parentName="p">{`yarn start`}</inlineCode>{`, or, if your app is running, you’ll need to restart it to get access to this token.`}</p>
    <h2 {...{
      "id": "connect-to-apollo"
    }}>{`Connect to Apollo`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`react-apollo`}</inlineCode>{` library gives us a component that we need to put at the base of our application. Typically the best place for things that need to wrap the entire application is at the root of the application. For us, that’s at `}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{`.`}</p>
    <p>{`Add these two imports to `}<inlineCode parentName="p">{`src/index.js`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srcindexjs"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
`}</code></pre>
    <p>{`Next, make your client by providing a URI for the GitHub GraphQL API as well as an authorization header using the environmental variable you just added to `}<inlineCode parentName="p">{`.env.local`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srcindexjs-1"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const client = new ApolloClient({
  uri: 'https://api.github.com/graphql',
  headers: {
    authorization: \`Bearer \${process.env.REACT_APP_GITHUB_PERSONAL_ACCESS_TOKEN}\`,
  },
});
`}</code></pre>
    <p>{`Now we can wrap our application with `}<inlineCode parentName="p">{`ApolloProvider`}</inlineCode>{`. At the same time, we’ll pass in the client we just made as a prop to the `}<inlineCode parentName="p">{`ApolloProvider`}</inlineCode>{` component. Replace:`}</p>
    <h5 {...{
      "id": "srcindexjs-2"
    }}>{`src/index.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<Router>
  <App />
</Router>
`}</code></pre>
    <p>{`With:`}</p>
    <h5 {...{
      "id": "srcindexjs-3"
    }}>{`src/index.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<ApolloProvider client={client}>
  <Router>
    <App />
  </Router>
</ApolloProvider>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <h2 {...{
      "id": "fetch-data"
    }}>{`Fetch data`}</h2>
    <h3 {...{
      "id": "imports"
    }}>{`Imports`}</h3>
    <p>{`Add the following imports at the top of `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
`}</code></pre>
    <h3 {...{
      "id": "query"
    }}>{`Query`}</h3>
    <p>{`Next we’ll assemble our GraphQL query to fetch only the data we need from the GraphQL API. We’ll do this using the `}<inlineCode parentName="p">{`gql`}</inlineCode>{` helper we just imported. The `}<inlineCode parentName="p">{`gql`}</inlineCode>{` helper lets you write GraphQL queries using interpolated strings (backticks) in JavaScript. In addition, we’ll be using the `}<inlineCode parentName="p">{`Query`}</inlineCode>{` component from `}<inlineCode parentName="p">{`react-apollo`}</inlineCode>{` which gives us some great information about our query’s loading state in addition to the data.`}</p>
    <p>{`You can use GitHub’s `}<a parentName="p" {...{
        "href": "https://developer.github.com/v4/explorer/"
      }}>{`explorer`}</a>{` tool to write and test your own queries. Try copying the query below and experiment with changing the properties. You can also click the “Docs” button in the top right of the explorer to view all of the available data and query parameters.`}</p>
    <p>{`If you’d like some more information regarding writing queries and using the Query component, we recommend `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/tutorial/queries"
      }}>{`Apollo’s documentation`}</a>{` on this topic.`}</p>
    <p>{`Add this after your imports:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-1"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`const REPO_QUERY = gql\`
  query REPO_QUERY {
    # Let's use carbon as our organization
    organization(login: "carbon-design-system") {
      # We'll grab all the repositories in one go. To load more resources
      # continuously, see the advanced topics.
      repositories(first: 75, orderBy: { field: UPDATED_AT, direction: DESC }) {
        totalCount
        nodes {
          url
          homepageUrl
          issues(filterBy: { states: OPEN }) {
            totalCount
          }
          stargazers {
            totalCount
          }
          releases(first: 1) {
            totalCount
            nodes {
              name
            }
          }
          name
          updatedAt
          createdAt
          description
          id
        }
      }
    }
  }
\`;
`}</code></pre>
    <h3 {...{
      "id": "helpers"
    }}>{`Helpers`}</h3>
    <p>{`Below that, we should have our table headers set in a previous step that are good to go. Let’s also keep our example rows below that.`}</p>
    <p>{`Our last column in the data table will be a comma-separated list of repository and home page links, so let’s create a component called `}<inlineCode parentName="p">{`LinkList`}</inlineCode>{`.`}</p>
    <p>{`Import `}<inlineCode parentName="p">{`Link`}</inlineCode>{` at the top of `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-2"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Link } from 'carbon-components-react';
`}</code></pre>
    <p>{`Then use `}<inlineCode parentName="p">{`Link`}</inlineCode>{` in this component that has two props (`}<inlineCode parentName="p">{`url`}</inlineCode>{` and `}<inlineCode parentName="p">{`homepageUrl`}</inlineCode>{`) and returns an unordered list. If the repository does not have a home page URL, only render the repository link.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-3"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const LinkList = ({ url, homepageUrl }) => (
  <ul style={{ display: 'flex' }}>
    <li>
      <Link href={url}>GitHub</Link>
    </li>
    {homepageUrl && (
      <li>
        <span>&nbsp;|&nbsp;</span>
        <Link href={homepageUrl}>Homepage</Link>
      </li>
    )}
  </ul>
);
`}</code></pre>
    <p>{`As a final helper, let’s create a function that transforms row data to our expected header keys. Notice how we’re using our new `}<inlineCode parentName="p">{`LinkList`}</inlineCode>{` component to generate the value of the `}<inlineCode parentName="p">{`links`}</inlineCode>{` key in each row.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-4"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const getRowItems = rows =>
  rows.map(row => ({
    ...row,
    key: row.id,
    stars: row.stargazers.totalCount,
    issueCount: row.issues.totalCount,
    createdAt: new Date(row.createdAt).toLocaleDateString(),
    updatedAt: new Date(row.updatedAt).toLocaleDateString(),
    links: <LinkList url={row.url} homepageUrl={row.homepageUrl} />,
  }));
`}</code></pre>
    <h3 {...{
      "id": "query-component"
    }}>{`Query component`}</h3>
    <p>{`At this point, we should run our query and `}<inlineCode parentName="p">{`console.log()`}</inlineCode>{` the results to verify that the request is working.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Query`}</inlineCode>{` component from `}<inlineCode parentName="p">{`react-apollo`}</inlineCode>{` lets us render different content based on the state of our request. When `}<inlineCode parentName="p">{`loading`}</inlineCode>{` is true, we’ll render `}<inlineCode parentName="p">{`Loading...`}</inlineCode>{` for the time being. If there’s an issue, we’ll render the corresponding error message.`}</p>
    <p>{`Finally, if neither of those are true, it means we have our data! One nice advantage of GraphQL is that as long as there are no errors, we can be certain the properties on the data we requested aren’t `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`.`}</p>
    <p>{`We need to render the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` in `}<inlineCode parentName="p">{`Query`}</inlineCode>{`’s `}<inlineCode parentName="p">{`return()`}</inlineCode>{` statement once the request is no longer loading and when there are no errors. To do so, replace the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` line with the following `}<inlineCode parentName="p">{`Query`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-5"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<RepoTable headers={headers} rows={rows} />
`}</code></pre>
    <p>{`Notice how we’re passing the `}<inlineCode parentName="p">{`REPO_QUERY`}</inlineCode>{` that we previously defined into the `}<inlineCode parentName="p">{`query`}</inlineCode>{` prop.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-6"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Query query={REPO_QUERY}>
  {({ loading, error, data: { organization } }) => {
    // Wait for the request to complete
    if (loading) return 'Loading...';

    // Something went wrong with the data fetching
    if (error) return \`Error! \${error.message}\`;

    // If we're here, we've got our data!
    console.log(organization);

    return (
      <>
        <RepoTable headers={headers} rows={rows} />
      </>
    );
  }}
</Query>
`}</code></pre>
    <p>{`The page will look the same as we’re still rendering our static example rows, but if you view your browser’s console (e.g. `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/chrome-devtools/"
      }}>{`Chrome DevTools`}</a>{`), you should see the response from GitHub!`}</p>
    <h2 {...{
      "id": "populate-data-table"
    }}>{`Populate data table`}</h2>
    <p>{`Now that we have that data, let’s populate the data table. Replace `}<inlineCode parentName="p">{`console.log(organization);`}</inlineCode>{` with the following that destructures the `}<inlineCode parentName="p">{`organization`}</inlineCode>{` object. Once we have the repositories, we can call our `}<inlineCode parentName="p">{`getRowItems()`}</inlineCode>{` helper to build the data table rows.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-7"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// If we're here, we've got our data!
const { repositories } = organization;
const rows = getRowItems(repositories.nodes);
`}</code></pre>
    <p>{`Then, towards the top of `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{` delete the `}<inlineCode parentName="p">{`rows`}</inlineCode>{` array because we no longer need the example rows.`}</p>
    <h3 {...{
      "id": "render-repository-descriptions"
    }}>{`Render repository descriptions`}</h3>
    <p>{`The data table component and its pieces use a common React pattern called `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/render-props.html"
      }}>{`render props`}</a>{`. This a really powerful way for libraries to give developers control of rendering and manipulating their data.`}</p>
    <p>{`Revisiting `}<inlineCode parentName="p">{`RepoTable.js`}</inlineCode>{`, we are already passing in our row objects along with headers for each column. The `}<inlineCode parentName="p">{`render`}</inlineCode>{` prop is being used to tell the data table how to render the headers and rows. That prop takes a function that receives the processed headers and rows as arguments as well as some helper functions for rendering the table.`}</p>
    <p>{`One common hurdle with the data table is how to access data that might not correspond with a table column but is needed to compute the value of a cell that does. The data table component processes and controls only the row properties which corresponds to headers (columns). Because of this, the `}<inlineCode parentName="p">{`rows`}</inlineCode>{` object you get access to in the render prop function is `}<em parentName="p">{`different`}</em>{` than the one you passed in to the `}<inlineCode parentName="p">{`rows`}</inlineCode>{` prop.`}</p>
    <p>{`We need to modify one aspect of the data table because if you expand a row, it says `}<inlineCode parentName="p">{`Row description`}</inlineCode>{`. We want to update that with the repository description coming from the GitHub API. This is an example of where we need a simple look-up function to find the data we care about - data that does not directly correspond to a column.`}</p>
    <p>{`To do so, in `}<inlineCode parentName="p">{`RepoTable.js`}</inlineCode>{`, add this look-up function as the first lines inside the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{`. This should go immediately before the component’s `}<inlineCode parentName="p">{`return()`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepotablejs"
    }}>{`src/content/RepoPage/RepoTable.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const getRowDescription = rowId => {
  const row = rows.find(({ id }) => id === rowId);
  return row ? row.description : '';
};
`}</code></pre>
    <p>{`Finally, in `}<inlineCode parentName="p">{`RepoTable.js`}</inlineCode>{`, replace `}<inlineCode parentName="p">{`<p>Row description</p>`}</inlineCode>{` with:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepotablejs-1"
    }}>{`src/content/RepoPage/RepoTable.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p>{getRowDescription(row.id)}</p>
`}</code></pre>
    <h2 {...{
      "id": "add-loading"
    }}>{`Add loading`}</h2>
    <p>{`At this point, the first time that you visit the repositories page, we’re querying the GitHub API and rendering the response through the `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` component. We could stop here, but there’s more to be done! Let’s replace the `}<inlineCode parentName="p">{`Loading...`}</inlineCode>{` string with the `}<a parentName="p" {...{
        "href": "http://react.carbondesignsystem.com/?selectedKind=DataTableSkeleton"
      }}>{`DataTableSkeleton component`}</a>{`.`}</p>
    <p>{`To do so, back to `}<inlineCode parentName="p">{`RepoPage.js`}</inlineCode>{`, add the `}<inlineCode parentName="p">{`DataTableSkeleton`}</inlineCode>{` import by modifying the existing `}<inlineCode parentName="p">{`carbon-components-react`}</inlineCode>{` import.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-8"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Link, DataTableSkeleton } from 'carbon-components-react';
`}</code></pre>
    <p>{`Then replace the `}<inlineCode parentName="p">{`if (loading) return 'Loading...';`}</inlineCode>{` with:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-9"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (loading)
  return (
    <DataTableSkeleton
      columnCount={headers.length + 1}
      rowCount={10}
      headers={headers}
    />
  );
`}</code></pre>
    <p>{`We need to tell the loading skeleton how many rows to render, so let’s use 10 skeleton rows to prepare for the next enhancement…`}</p>
    <h2 {...{
      "id": "add-pagination"
    }}>{`Add pagination`}</h2>
    <p>{`Pagination! Instead of rendering every repository, let’s add pagination to the data table to only render 10 at a time. Depending on your specific requirements, you may need to fetch new data each time that you interact with the pagination component, but for simplicity, we’re going to make one request to fetch all data, and then paginate the in-memory row data.`}</p>
    <p>{`We’ll be using `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`React Hooks`}</a>{` to manage our state. Hooks are a relatively new React API that allows us to author a React component’s stateful logic in a function component rather than a class component. Using hooks means we don’t need to worry about complex lifecycle methods.`}</p>
    <p>{`Import React’s `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-state.html"
      }}>{`useState`}</a>{` by modifying the `}<inlineCode parentName="p">{`React`}</inlineCode>{` import.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-10"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React, { useState } from 'react';
`}</code></pre>
    <p>{`Then initialize the new state variables that we’ll use for pagination as the first lines inside the `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component, above the `}<inlineCode parentName="p">{`return()`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-11"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const [totalItems, setTotalItems] = useState(0);
const [firstRowIndex, setFirstRowIndex] = useState(0);
const [currentPageSize, setCurrentPageSize] = useState(10);
`}</code></pre>
    <p>{`This initializes the total number of rows and the index of the first row to `}<inlineCode parentName="p">{`0`}</inlineCode>{`, and the page size to `}<inlineCode parentName="p">{`10`}</inlineCode>{` as we also specified in our loading skeleton.`}</p>
    <p>{`Next we need to use the function that updates the `}<inlineCode parentName="p">{`totalItems`}</inlineCode>{` state, `}<inlineCode parentName="p">{`setTotalItems()`}</inlineCode>{`, after we destructure our `}<inlineCode parentName="p">{`organization.repositories`}</inlineCode>{`. Your block that transforms row data should look like:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-12"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// If we're here, we've got our data!
const { repositories } = organization;
setTotalItems(repositories.totalCount);
const rows = getRowItems(repositories.nodes);
`}</code></pre>
    <p>{`Then we need to update our `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` `}<inlineCode parentName="p">{`rows`}</inlineCode>{` prop to only render the subset of rows for the current “page”. Update `}<inlineCode parentName="p">{`<RepoTable headers={headers} rows={rows} />`}</inlineCode>{` to:`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-13"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<RepoTable
  headers={headers}
  rows={rows.slice(
    firstRowIndex,
    firstRowIndex + currentPageSize
  )}
/>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p><em parentName="p">{`Note: We only pass the rows that we want our table to display. We can do this by slicing the our array of rows depending on the first item and the page size.`}</em></p>
    <p>{`Finally, let’s add the `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` to update our state variables and cause the data table to render new rows.`}</p>
    <p>{`Import `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` by updating the `}<inlineCode parentName="p">{`carbon-components-react`}</inlineCode>{` import.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-14"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Link, DataTableSkeleton, Pagination } from 'carbon-components-react';
`}</code></pre>
    <p>{`Immediately after the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` closing tag (`}<inlineCode parentName="p">{`/>`}</inlineCode>{`), add the `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` component using the state variables that we previously initialized.`}</p>
    <h5 {...{
      "id": "srccontentrepopagerepopagejs-15"
    }}>{`src/content/RepoPage/RepoPage.js`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<Pagination
  totalItems={totalItems}
  backwardText="Previous page"
  forwardText="Next page"
  pageSize={currentPageSize}
  pageSizes={[5, 10, 15, 25]}
  itemsPerPageText="Items per page"
  onChange={({ page, pageSize }) => {
    if (pageSize !== currentPageSize) {
      setCurrentPageSize(pageSize);
    }
    setFirstRowIndex(pageSize * (page - 1));
  }}
/>
`}</code></pre>
    <p><em parentName="p">{`Note: The`}</em>{` `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` `}<em parentName="p">{`component isn’t inherently connected in any way to the`}</em>{` `}<inlineCode parentName="p">{`DataTable`}</inlineCode>{` `}<em parentName="p">{`- we need to tell it what to do when a change occurs using the`}</em>{` `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` `}<em parentName="p">{`prop. This includes both page size changes and displaying different rows.`}</em></p>
    <p><em parentName="p">{`Note: Like the other Carbon React components,`}</em>{` `}<inlineCode parentName="p">{`Pagination`}</inlineCode>{` `}<em parentName="p">{`component examples can be found in `}<a parentName="em" {...{
          "href": "http://react.carbondesignsystem.com/?selectedKind=Pagination"
        }}>{`Storybook`}</a>{` by browsing the story and knobs.`}</em></p>
    <p>{`That does it! Your data table should fetch GitHub data on first render. You can expand each row to see the repository’s description. You can modify the pagination items per page and cycle through pages or jump to a specific page of repositories.`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/react-step-1#continuous-integration-(ci)-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 3"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin react-step-3
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/react-step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial"
      }}>{`carbon-tutorial`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`react-step-3`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: react-step-3`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      